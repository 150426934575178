import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import { useStyles } from "./SolutionCards.styles";
import { IconSolarEP } from "../../../../common/icons/IconSolarEP";
import Button from "@material-ui/core/Button";

const SolarEpCard = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <Typography variant="h3" className={classes.title}>
          SolarEP
        </Typography>
        <CardContent className={classes.content}>
          <IconSolarEP />
          <Typography variant="body2" className={classes.description}>
            Generate more power and optimize the performance of various solar PV
            assets using an all-in-one platform
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" className={classes.listTitle}>
        <HeadingDash justify="flex-start" />
        Get the most out of your PV portfolio investment
      </Typography>
      <ul className={classes.bullets}>
        {/*<li>*/}
        {/*  Manage and monitor solar energy sources to improve asset profitability*/}
        {/*</li>*/}
        {/*<li>Easily connect with all your internal & 3rd party systems</li>*/}
        {/*<li>Modular & flexible with top notch technologies</li>*/}
        <li>Manage sources to improve profitability</li>
        <li>Easily connect with all systems</li>
        <li>Modular & flexible with top notch technologies</li>
      </ul>
      <Box className={classes.bottomBtnBox}>
        <Button variant="contained" color="secondary" size="small">
          Read more
        </Button>
      </Box>
    </>
  );
};

export default SolarEpCard;
