import * as React from "react";
import { useStyles } from "./ChooseEpSection.styles";
import { Grid, Typography } from "@material-ui/core";
import { IconBrain } from "../../../common/icons/IconBrain";
import { IconDataPlot } from "../../../common/icons/IconDataPlot";
import { IconBetterPerformance } from "../../../common/icons/IconBetterPerformance";
import HeadingDash from "../../../common/components/HeadingDash/HeadingDash";
import { DataPilesImage } from "../../../common/images/DataPilesImage";
import { IconRiskMinimization } from "../../../common/icons/IconRiskMinimization";
import { IconCostReduction } from "../../../common/icons/IconCostReduction";
import IconCard from "../../../common/components/IconCard/IconCard";

const ChooseEpSection = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid container spacing={5} justifyContent="space-around">
        <Grid item xs={12} className={classes.topBox}>
          <Typography variant="h2">
            <HeadingDash justify="flex-start" />
            Why you should choose Energy Platform?
          </Typography>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.boxLeft}>
          <IconBrain />
          <Typography variant="h4">
            Truly integrated energy solution Machine-Learning Analysis
          </Typography>
          <Typography variant="body2">
            The ML models are constantly analyzing incoming data and looking for
            any abnormalities and patterns to improve the longevity and
            efficiency of the DERS.
          </Typography>
          <Grid
            container
            item
            xs={6}
            lg={6}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            direction="row"
            className={classes.cardContainer}
          >
            <IconCard
              icon={<IconBetterPerformance />}
              text="Custom reports"
              className={classes.iconCard}
            />
            <IconCard
              icon={<IconCostReduction />}
              text="Powerful API"
              className={classes.iconCard}
            />
            <IconCard
              icon={<IconRiskMinimization />}
              text="Data export"
              className={classes.iconCard}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.boxRight}>
          <IconDataPlot />
          <Typography variant="h4">Robust Data Visualization</Typography>
          <Typography variant="body2">
            The white-label customizable dashboards allow setting up tailored
            visualizations that present essential information. Our data explorer
            allows you to deep dive into the details of your data.
          </Typography>
          <Grid>
            <Grid
              container
              justifyContent="center"
              className={classes.dataImgWrapper}
            >
              <DataPilesImage />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
export default ChooseEpSection;
