import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "90px 10% 90px 10%",
    backgroundColor: "#F6F3F0",
  },
  subheader: {
    marginTop: "30px",
    fontWeight: 600,
  },
  description: {
    margin: "15px 0 30px 0",
  },
  imgWrapper: {
    paddingTop: "40px",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "center",
    },
  },
  dashboardIcon: {
    width: "80%",
    marginLeft: "10%",
  },
}));
