import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import { useStyles } from "./SolutionCards.styles";
import { IconEVoEP } from "../../../../common/icons/IconEVoEP";
import Button from "@material-ui/core/Button";

const SolarEpCard = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <Typography variant="h3" className={classes.title}>
          EVoEP
        </Typography>
        <CardContent className={classes.content}>
          <IconEVoEP />
          <Typography variant="body2" className={classes.description}>
            All-in-one solution to ensure more efficient management of your EV
            charging infrastructure
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" className={classes.listTitle}>
        <HeadingDash justify="flex-start" />
        EV infrastructure management in one place
      </Typography>
      <ul className={classes.bullets}>
        {/*<li>Connect all charge points with EV charging networks</li>*/}
        {/*<li>Integrate with your internal systems and third-party services</li>*/}
        {/*<li> V2G, PV, energy storage and optimization features</li>*/}

        <li>Connect charge points with EV networks</li>
        <li>Integrate system with third-party services</li>
        <li> V2G, PV, storage and optimization features</li>
      </ul>
      <Box className={classes.bottomBtnBox}>
        <Button variant="contained" color="secondary" size="small">
          Read more
        </Button>
      </Box>
    </>
  );
};

export default SolarEpCard;
