import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    padding: "100px 10% 100px 10%",
  },
  header: {
    color: "white",
    textAlign: "center",
  },
  description: {
    color: "white",
    textAlign: "center",
    maxWidth: "650px",
    margin: "24px auto 100px auto",
  },
  integrationsHalf: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
});
