import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  root: {
    padding: "100px 10% 100px 10%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& button": {
      color: "#fff",
      padding: "0px 10px",
      margin: "10px",
      border: "none",
      boxSizing: "border-box",
      borderRadius: "30px",
      fontWeight: "800",
      height: "40px",
    },
    "& img": {
      width: "140px",
      height: "140px",
      margin: "auto",
      "@media (max-width:900px)": {
        width: "100%",
      },
    },
  },
  header: {
    color: "white",
    textAlign: "center",
  },
  description: {
    color: "white",
    textAlign: "center",
    maxWidth: "650px",
    margin: "24px auto 100px auto",
  },
});
