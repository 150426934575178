import React from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import HeadingDash from "../../../../common/components/HeadingDash/HeadingDash";
import { useStyles } from "./SolutionCards.styles";
import { IconOptoEP } from "../../../../common/icons/IconOptoEP";
import Button from "@material-ui/core/Button";

const SolarEpCard = (): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <Typography variant="h3" className={classes.title}>
          OptoEP
        </Typography>
        <CardContent className={classes.content}>
          <IconOptoEP />
          <Typography variant="body2" className={classes.description}>
            Maximize renewable generation consumption for the greenest, most
            cost-efficient outcome
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h4" className={classes.listTitle}>
        <HeadingDash justify="flex-start" />
        {/*Efficient sites energy optimization*/}
        Efficient site optimization and energy control
      </Typography>
      <ul className={classes.bullets}>
        <li>Save money through dynamic tariffs</li>
        <li>Avoid penalties for exceeding capacity</li>
        <li>Maximize on-site renewables for charging</li>
      </ul>
      <Box className={classes.bottomBtnBox}>
        <Button variant="contained" color="secondary" size="small">
          Read more
        </Button>
      </Box>
    </>
  );
};

export default SolarEpCard;
