import React from "react";
import FutureProofEpSection from "../Sections/FutureProofEpSection/FutureProofEpSection";
import ChooseEpSection from "../Sections/ChooseEpSection/ChooseEpSection";
import IntegrationsSection from "../Sections/IntegrationsSection/IntegrationsSection";
import CapabilitiesSection from "../Sections/CapabilitiesSection/CapabilitiesSection";
import ServicesSection from "../Sections/ServicesSection/ServicesSection";
import StartingSection from "../../common/sections/StartingSection/StartingSection";
import SummarySection from "../../common/sections/SummarySection/SummarySection";
import { EnergyPlatformIllustration } from "../../common/images/illustrations/EnergyPlatformIllustration";
import OurEcosystemSection from "../../common/sections/OurEcosystemSection/OurEcosystemSection";
import { AssetManagementImage } from "../../common/images/AssetManagementImage";
import AllFeaturesSection from "../../common/sections/AllFeaturesSection/AllFeaturesSection";
import { IconSolutions } from "../../common/icons/IconSolutions";
import { EmailInput } from "../../common/Layout/Layout";
import { EpServicesEnum } from "../../api/useEmailService";

const AllFeaturesSectionFeatures = [
  "Global market reach out",
  "CRM capabilities",
  "Control Centre with ergonomic 360 view for the operators",
  "Trader Centre with market integration and support tools and dashboards",
  "Integrated commissioning tool with the Installer portal and fleet management",
  "Algo-Trader (AI/ML based) for further optimization of portfolio revenue",
  "Customer portal with telemetry, availability management, billing, and notifications",
  "Business dashboards for executive team providing high level overview of the portfolio",
];

interface EpMainProps {
  emailInputRef: EmailInput;
}

const EnergyPlatformMain = ({ emailInputRef }: any): JSX.Element => {
  return (
    <>
      <StartingSection
        service={EpServicesEnum.Ep}
        emailInputRef={emailInputRef}
        title="Join the All-in-One Solution for the Distributed Energy Resources."
        subtitle="Monitor, Manage, and Report Distributed Energy Resources on one platform."
        illustration={<EnergyPlatformIllustration />}
      />
      <SummarySection>
        <>
          <b> EnergyPlatform.TECH </b>
          is an asset performance management
          <span> platform for distributed energy resources</span> that allow{" "}
          <br /> asset managers, performance engineers and O&M teams to identify
          losses, pinpoint cause of budget and model deviation.
        </>
      </SummarySection>
      <FutureProofEpSection />
      <ServicesSection />
      <ChooseEpSection />
      <OurEcosystemSection
        header="Asset Management"
        description="Complex asset management allows you to manage our inventory,
        manufacturers, suppliers and run day-to-day operations to ensure that
        all of your devices work flawlessly."
        picture={<AssetManagementImage />}
      />
      <CapabilitiesSection />
      <IntegrationsSection />
      <AllFeaturesSection
        heading="Meet every need with one solution"
        description="We developed a complete energy management solution for energy & asset management, monitoring and reporting."
        features={AllFeaturesSectionFeatures}
        icon={<IconSolutions />}
      />
    </>
  );
};

export default EnergyPlatformMain;
