import * as React from "react";
import dashboard from "../../assets/icons/svg/dashboard.svg";

interface Props {
  className: string;
}

export const IconDashboard = ({ className }: Props) => (
  <img src={dashboard} alt="Dashboard icon" className={className} />
);
