import React from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./IntegrationsSection.styles";
import HeadingDash from "../../../common/components/HeadingDash/HeadingDash";
import IntegrationsItem from "./IntegrationItem/IntegrationItem";

const IntegrationsSection = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <HeadingDash justify="center" />
      <Typography variant="h2" className={classes.header}>
        Wide range of integrations
      </Typography>
      <Typography variant="body2" className={classes.description}>
        Complex asset management allows you to manage our inventory,
        manufacturers, suppliers and run day-to-day operations to ensure that
        all of your devices work flawlessly.
      </Typography>
      <div>
        <div className={classes.integrationsHalf}>
          <IntegrationsItem>OCPP</IntegrationsItem>
          <IntegrationsItem>Geo Engine</IntegrationsItem>
          <IntegrationsItem>twilio</IntegrationsItem>
          <IntegrationsItem>Weather API</IntegrationsItem>
        </div>
        <div className={classes.integrationsHalf}>
          <IntegrationsItem>Utility API</IntegrationsItem>
          <IntegrationsItem>electricityMap</IntegrationsItem>
          <IntegrationsItem>Forecast Engine</IntegrationsItem>
          <IntegrationsItem>IoT Base Infrastructure</IntegrationsItem>
        </div>
      </div>
    </section>
  );
};

export default IntegrationsSection;
