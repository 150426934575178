import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        width: "220px",
        marginBottom: "25px",
    },
    text: {
        color: "white",
        marginLeft: "10px",
        fontWeight: 600,
    }
});
