import * as React from "react";
import { makeStyles } from "@material-ui/core";
import img from "../../../../assets/images/monitoringGraph.svg";

const useStyles = makeStyles({
  img: {
    pointerEvents: "none",
    width: "130%",
    "@media (max-width:1440px)": {
      marginLeft: "-10%",
      width: "120%",
    },
  },
});

export const MonitoringGraphImage = (): JSX.Element => {
  return (
    // <StaticImage
    //   src={"../../../../public/images/monitoringGraph.svg"}
    //   width={1100}
    //   quality={95}
    //   placeholder="tracedSVG"
    //   formats={["auto", "webp", "avif"]}
    //   alt={"Energy Platform Insight Image"}
    //   className={useStyles().img}
    // />
    <img className={useStyles().img} src={img} alt={"Monitoring Graph Image"} />
  );
};
