import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./ServicesSection.styles";
import HeadingDash from "../../../common/components/HeadingDash/HeadingDash";
import SolarEpCard from "./SolutionCards/SolarEpCard";
import EVOEpCard from "./SolutionCards/EVOEpCard";
import OptoEpCard from "./SolutionCards/OptoEpCard";

const ServicesSection = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      `
      <HeadingDash justify="center" />
      <Typography variant="h2" className={classes.header}>
        Three features. One goal.
      </Typography>
      <Typography variant="body2" className={classes.description}>
        Distributed Energy Resources in one platform. Learn more about our
        industry solutions.
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={4}>
          <SolarEpCard />
        </Grid>
        <Grid item xs={12} lg={4}>
          <EVOEpCard />
        </Grid>
        <Grid item xs={12} lg={4}>
          <OptoEpCard />
        </Grid>
      </Grid>
    </section>
  );
};

export default ServicesSection;
