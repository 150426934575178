import React from "react";
import { useStyles } from "./CapabilitiesSection.styles";
import { Divider, Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../../common/components/HeadingDash/HeadingDash";
import { IconDashboard } from "../../../common/icons/IconDashboard";

const CapabilitiesSection = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.container}>
      <Grid container alignItems="center" spacing={8}>
        <Grid item xs={12} md={5}>
          <HeadingDash justify={"flex-start"} />
          <Typography variant="h2">
            What are your capabilities with
            <br />
            our service?
          </Typography>
          <Typography variant="body1" className={classes.subheader}>
            Truly integrated energy solution Machine-Learning Analysis
          </Typography>
          <Typography variant="body2" className={classes.description}>
            Fully-features control center allows securing high efficiency and
            reliability of the portfolio. Optimized for the smaller teams, it
            provides an ergonomic tool for keeping an eye on the sites.
          </Typography>
          <Divider />
          <Typography variant="body1" className={classes.subheader}>
            End-Customer Management
          </Typography>
          <Typography variant="body2" className={classes.description}>
            Provides detailed information for the site owners, end-customers,
            and contacts. Allowing management of the site, making payments, and
            checking bills.
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} className={classes.imgWrapper}>
          <IconDashboard className={classes.dashboardIcon} />
        </Grid>
      </Grid>
    </section>
  );
};

export default CapabilitiesSection;
