import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: "100vw",
    border: "none",
    fontStyle: "normal",
    color: "#000",
    backgroundColor: "#fff",
    padding: "120px 10% 120px 10%",
  },
  topBox: {
    marginLeft: "2%",
    marginTop: "-2%",
  },
  boxLeft: {
    "& img": {
      width: "20px",
      [theme.breakpoints.up("xs")]: {
        paddingTop: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "60px",
      },
    },
  },
  boxRight: {
    marginLeft: "5vw",
    "& img": {
      width: "20px",
      [theme.breakpoints.up("xs")]: {
        paddingTop: "20px",
      },
      [theme.breakpoints.up("lg")]: {
        paddingTop: "60px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  cardContainer: {
    paddingTop: "7vh",
    display: "inline-flex",
    minWidth: "100%",
    flexWrap: "nowrap",
    gap: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      paddingTop: "9vh",
      alignItems: "center",
      gap: theme.spacing(3),
    },
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "60%",
      margin: "auto",
    },
  },
  iconCard: {
    width: "150px",
    "& img": {
      width: "40px !important",
      height: "40px !important",
      [theme.breakpoints.up("xs")]: {
        width: "60px !important",
        height: "60px !important",
        paddingTop: "4px",
      },
    },
  },
  dataImgWrapper: {
    "& img": {
      width: "100%",
      minWidth: "200px",
      maxWidth: "500px",
      paddingTop: "70px",
    },
  },
}));
