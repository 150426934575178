import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 30px 30px 0px #071E570D",
    borderRadius: "30px",
    marginBottom: "20px",
    height: "45vh",
  },
  title: {
    margin: "24px 0",
    fontWeight: 600,
    textAlign: "center",
  },
  subTitle: {
    textAlign: "center",
  },
  content: {
    textAlign: "center",
    "& img": {
      width: "10vw",
      padding: "10px",
      margin: "auto",
      "@media (max-width:900px)": {
        width: "100%",
      },
    },
  },
  header: {
    textAlign: "center",
  },
  description: {
    textAlign: "center",
    maxWidth: "650px",
    margin: "24px auto 10px auto",
  },
  listTitle: {
    color: "white",
  },
  bullets: {
    marginTop: "30px",
    listStyle: "none",
    color: "white",
    "& li": {
      fontSize: "14px",
      fontWeight: 300,
    },
    "& li::before": {
      color: theme.palette.secondary.main,
      content: '"\\2022"',
      fontSize: "44px",
      fontWeight: "bold",
      display: "inline-block",
      width: ".6em",
      marginLeft: "-1em",
      verticalAlign: "middle",
      lineHeight: "34px",
    },
  },
  bottomBtnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));
