import React from "react";
import {Typography} from "@material-ui/core";
import {IconCircleCheck} from "../../../../common/icons/IconCircleCheck";
import {useStyles} from "./IntegrationItem.styles";

interface IntegrationsItemProps {
    children: JSX.Element | string;
}

const IntegrationsItem = ({ children }: IntegrationsItemProps): JSX.Element => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <IconCircleCheck />
            <Typography variant="body1" className={classes.text}>
                {children}
            </Typography>
        </div>
    );
}

export default IntegrationsItem;