import * as React from "react";
import { useStyles } from "./FutureProofEpSection.styles";
import { Grid, Typography } from "@material-ui/core";
import { MonitoringGraphImage } from "./MonitoringGraph/MonitoringGraphImage";
import HeadingDash from "../../../common/components/HeadingDash/HeadingDash";

const FutureProofEpSection = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.futureProofSection}>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={12} lg={5} className={classes.leftBox}>
          <Typography variant="h2">
            <HeadingDash justify="flex-start" />
            Future-proof solution for energy monitoring
          </Typography>
          <ul>
            <li>Energy and Financial Reporting</li>
            <li>Remote Operations and Maintenance</li>
            <li>Grid Services/Demand Response/Trading</li>
          </ul>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MonitoringGraphImage />
        </Grid>
      </Grid>
    </section>
  );
};
export default FutureProofEpSection;
